import React from "react"
import { Flex, Box } from "rebass"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"

const UebermichPage = () => {
  const data = useStaticQuery(graphql`
    query {
      anna: file(relativePath: { eq: "anna.jpg" }) {
        childImageSharp {
          fixed(width: 600, height: 450, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Über mich" />

      <Flex
        flexWrap='wrap'
        px={[0, 4]}
        py={4}
        style={{
          backgroundColor: '#efe7e3',
        }}>
        <Box
          px={[3, 4]}
          paddingTop={4}
          bg='white'
          width={[1, 1/2]}>
          <Title>Über mich</Title>
        </Box>
        <Box p={[0]} width={[1, 1/2]} />
        <Box px={[3, 4]} width={[1, 1/2]} bg='white'>
          <p>Geboren 1979, studierte ich nach dem Abitur Jura in Göttingen, Madrid und Berlin. Das Refrendariat absolvierte ich in Frankfurt am Main und München. Nach dem 2. Staatsexamen arbeitete ich zunächst als Anwältin und Unternehmensjuristin.</p>
          <p>Aufgrund eigener sehr guter Erfahrungen mit der Akupunktur entwickelte ich schon früh Begeisterung für die Chinesische Medizin. 2010 ließ ich mich als Heilpraktikerin ausbilden und absolvierte anschließend eine zweijährige Vollzeitausbildung in Akupunktur an der „Arche Medica – Akademie für Heilpraktiker Berlin“. Im Anschluss an meine Ausbildung war ich zunächst auf einer Assistenzstelle in einer Arztpraxis mit Schwerpunkt TCM in Berlin tätig. Durch kontinuierliche Fortbildungen und Seminare konnte ich meine Kenntnisse weiter vertiefen und mein Behandlungssprektrum erweitern, wie zum Beispiel im Bereich Kosmetische Akupunktur oder Akupunktur speziell für Kinder.</p>
          <p>Neben meinem Diplom in Akupunktur habe ich eine Ausbildung in der chinesischen Arzneimitteltherapie, chinesischen Diätetik und Fußreflexzonentherapie.</p>
          <p>Seit 2014 arbeite ich als selbstständige Therapeutin in Frankfurt am Main, wo ich mit meinem Mann und meinen beiden Kindern lebe. Mein Anliegen heute ist es, die Gesundheit zu erhalten, bevor Krankheit entsteht oder Menschen zu helfen, ihre Gesundheit wieder zu erlangen.</p>
        </Box>
        <Box
          px={[0, 4]}
          width={[1, 1/2]}>
          <Img fixed={data.anna.childImageSharp.fixed} style={{ width: '100%' }} />
        </Box>
      </Flex>
    </Layout>
  )
}

export default UebermichPage
